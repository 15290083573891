import React, {useEffect, useRef, useState} from "react";
import { connect } from "react-redux";
import PageName from "../../common/pagename/pagename";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import axios from "axios";
import {
    APIKEY,
    formatDateAndTime,
    GATEWAYRRRPAYMENTURL,
    MERCHANTID,
    serverLink,
    URL_REDIRECT
} from "../../../resources/url";
import {useLocation} from "react-router-dom";
import Data from "bootstrap/js/src/dom/data";
import DataLoader from "../../common/dataLoader/dataLoader";
const CryptoJS = require("crypto-js");

function CheckPayment(props) {

    const [IsLoading, setIsLoading] = useState(false);
    const [showAdmission, setShowAdmission] = useState(0);
    const [appData, setAppData] = useState({});
    const [appFormData, setAppFormData] = useState({ APPID: ""});
    const [formData, setFormData] = useState({
        APPID: "",
        STUDENTID: "",
        PROG_ID: "",
        EMAIL: "",
        FIRST_NAME: "",
        LAST_NAME: "",
        MIDDLE_NAME: "",
        TELEPHONE: "",
        ADMISSION_TYPE: "",
        AMOUNT: 0,
        RRR: "",
        new_hash: "",
    });

    const handleChange = (e) => {
        setAppFormData({
            ...appFormData,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(appFormData.APPID.toString().trim() === "") {
            showAlert("Empty Field", "Please Enter Application/Jamb No.", "error");
            return false;
        }

        const sendData = {...appFormData}

        toast.info("Please wait...");
        setIsLoading(true)
        axios.post(`${serverLink}application/admission/status`, sendData)
            .then(result => {
                const response = result.data.message;
                const AppData = result.data.appData;
                console.log(result.data)
                if (response === 'invalid') {
                    showAlert("NO ADMISSION", "You have not been offered admission yet!. Kindly check back later", "error");
                } else if (response === 'error') {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                } else {
                    setAppData(AppData)
                    setFormData({
                        ...formData,
                        APPID: AppData.APPID,
                        PROG_ID: AppData.PROG_ID,
                        EMAIL: AppData.EMAIL,
                        TELEPHONE: AppData.TELEPHONE,
                        FIRST_NAME: AppData.FIRST_NAME,
                        MIDDLE_NAME: AppData.MIDDLE_NAME,
                        LAST_NAME: AppData.LAST_NAME,
                        ADMISSION_TYPE: AppData.ADMISSION_TYPE,
                        AMOUNT: AppData.ACCEPT_FEE,
                    })
                    setShowAdmission(1)
                    // toast.success("You have been offered provisional admission");
                }
                setIsLoading(false)
            })
            .catch(error => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your network connection and try again!",
                    "error"
                );
                setIsLoading(false)
            })

    }

    const generateInvoice = async (e) => {
        e.preventDefault();
        if(appFormData.APPID.toString().trim() === "") {
            showAlert("Empty Field", "Please Enter Application/Jamb No.", "error");
            return false;
        }

        const sendData = {...appFormData}

        toast.info("Please wait...");
        setIsLoading(true)
        axios.post(`${serverLink}application/admission/status`, sendData)
            .then(result => {
                const response = result.data.message;
                const AppData = result.data.appData;
                console.log(result.data)
                if (response === 'invalid') {
                    showAlert("NO ADMISSION", "You have not been offered admission yet!. Kindly check back later", "error");
                } else if (response === 'error') {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                } else {
                    setAppData(AppData)
                    setFormData({
                        ...formData,
                        APPID: AppData.APPID,
                        PROG_ID: AppData.PROG_ID,
                        EMAIL: AppData.EMAIL,
                        TELEPHONE: AppData.TELEPHONE,
                        FIRST_NAME: AppData.FIRST_NAME,
                        MIDDLE_NAME: AppData.MIDDLE_NAME,
                        LAST_NAME: AppData.LAST_NAME,
                        ADMISSION_TYPE: AppData.ADMISSION_TYPE,
                    })
                    setShowAdmission(1)
                    // toast.success("You have been offered provisional admission");
                }
                setIsLoading(false)
            })
            .catch(error => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your network connection and try again!",
                    "error"
                );
                setIsLoading(false)
            })

    }

    const makeRemitaPayment = () => {
        let merchantId = "2547916";
        let apiKey = "1946";
        let serviceTypeId = "4430731";
        let d = new Date();
        let orderId = d.getTime();
        let totalAmount = 10000;
        let apiHash = CryptoJS.SHA512(merchantId+serviceTypeId+orderId+totalAmount+apiKey);

        let req = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `remitaConsumerKey=${merchantId},remitaConsumerToken=${apiHash}`,
            },
            body: JSON.stringify({
                "serviceTypeId": serviceTypeId,
                "amount": totalAmount,
                "orderId": orderId,
                "payerName": 'Pete Attah',
                "payerEmail": 'pete@domain.com',
                "payerPhone": '808080808080',
                "description": "Your payment description here"
            }),
        };
        toast.info("Please wait...");
        fetch(`https://remitademo.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api/paymentinit`, req)
            .then((response) => response.json())
            .then(async (responseJson) => {
                console.log(responseJson)
                if (responseJson.statuscode.toString() === "025"){
                    let RRR = responseJson.RRR;
                    console.log(responseJson)
                    let new_hash_string = MERCHANTID+RRR+APIKEY;
                    let new_hash = CryptoJS.SHA512(new_hash_string);
                    let sendData = {
                        ...formData,
                        RRR: RRR,
                    }
                    setFormData({  ...formData,  RRR: RRR, new_hash: new_hash })
                    await axios.post(`${serverLink}application/admission/acceptance/invoice`, sendData)
                        .then(result => {
                            const response = result.data.message;
                            if (response === 'error'){
                                showAlert(
                                    "ERROR",
                                    "Something went wrong. Please try again!",
                                    "error"
                                );
                            } else {
                                setShowAdmission(2)
                                // toast.success("You have been offered provisional admission");
                            }
                            setIsLoading(false)
                        })
                        .catch(error => {
                            showAlert(
                                "NETWORK ERROR",
                                "Please check your network connection and try again!",
                                "error"
                            );
                            setIsLoading(false)
                        })
                }

            })
            .catch((error) => {
                console.log(error);
            });
    }

    const  checkPaymentStatus = async () => {
        let merchantId = "2547916";
        let apiKey = "1946"
        let serviceTypeId = "4430731"
        let d = new Date();
        let orderId = d.getTime();
        let totalAmount = "10000";
        let rrr = "your_generated_rrr";
        let apiHash = CryptoJS.SHA512(rrr + apiKey + merchantId);

        let req = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `remitaConsumerKey=${merchantId},remitaConsumerToken=${apiHash}`,
            },
        };

        await fetch(`https://remitademo.net/remita/exapp/api/v1/send/api/echannelsvc/${merchantId}/${rrr}/${apiHash}/status.reg`, req)
            .then((response) => response.json())
            .then((responseJson) => {
                console.warn(responseJson);
            })
            .catch((error) => {
                console.error(error);
            });
    }



    const InitializePayment = () => {
        document.getElementById("pay").click();
    }

    return (
        <>
            <PageName page={"Check Payment Status"} />
            <section className="space-ptb border-top">
                <div className="container" style={{marginTop: '-30px'}}>
                    <div className="row justify-content-center">
                        <div className="col-xl-8 text-center">
                            <div className="section-title">
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-7 mb-2 text-center"  style={{height: '200px'}}>
                            <p>Please wait while the system is verifying your payment</p>
                            <DataLoader/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};

export default connect(mapStateToProps, null)(CheckPayment);