import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import NavigationBar from "../common/navigation/navigationbar";
import { BrowserRouter as Routers, Route, Routes } from "react-router-dom";
import Homepage from "../homepage/homepage";
import Footer from "../common/footer/footer";
import NotFound from "../404notfound/404notfound";
import About from "../about/about";
import Courses from "../admission/course/course";
import News from "../resources/news/news";
import NewsContent from "../resources/news/section/news-content";
import Contact from "../contact/contact";
import Gallery from "../resources/gallery/gallery";
import CourseDetails from "../admission/course/course-details";
import Staff from "../academics/staff/staff";
import StaffDetails from "../academics/staff/staff-details";
import Colleges from "../academics/colleges/colleges";
import CollegeDetails from "../academics/colleges/college-details";
import StudentLogin from "../portal/student-login";
import CheckAdmission from "../admission/admission/check-admission";
import CheckPayment from "../admission/admission/check-payment";
import CreateStudentLogin from "../admission/admission/create-student-login";


export default function PageRoutes() {
  return (
    <Routers>
      <NavigationBar />

      <Routes>
        <Route path={"/"} element={<Homepage />} />

         <Route path={"/admission/courses"} element={<Courses/>} />
         <Route path={"/admission/course/:slug"} element={<CourseDetails/>} />
         <Route path={"/admission/check-admission-status"} element={<CheckAdmission/>} />
         <Route path={"/admission/check-payment"} element={<CheckPayment/>} />
         <Route path={"/admission/create-login"} element={<CreateStudentLogin/>} />
         <Route path={"/admission/check-payment-status"} element={<CheckAdmission/>} />
         <Route path={"/academics/colleges"} element={<Colleges/>} />
         <Route path={"/academics/college/:slug"} element={<CollegeDetails/>} />
         <Route path={"/academics/staff"} element={<Staff/>} />
         <Route path={"/academics/staff/:slug"} element={<StaffDetails/>} />
         <Route path={"/about-us"} element={<About/>} />
         <Route path={"/contact-us"} element={<Contact/>} />
         <Route path={"/resources/news"} element={<News/>} />
         <Route path={"/resources/news/:slug"} element={<NewsContent/>} />
         <Route path={"/resources/gallery"} element={<Gallery/>} />

          {/*Student Login*/}
         <Route path={"/portal/student-login"} element={<StudentLogin/>} />

        {/*Application End*/}
        <Route path={"*"} element={<NotFound />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
        <div id="back-to-top" className="back-to-top">up</div>
      <Footer />
    </Routers>
  );
}
