import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setDepartmentListDetails, setGeneralDetails} from "../../../actions/setactiondetails";
import {currencyConverter, serverLink} from "../../../resources/url";
import Banner from "../../common/banner/banner";
import bg3 from "../../../images/bg/03.jpg";
import * as DOMPurify from 'dompurify';
import DataLoader from "../../common/dataLoader/dataLoader";
import CourseSide from "./section/course-side";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import PageName from "../../common/pagename/pagename";

const CourseDetails = (props)=>{
    const navigate = useNavigate();
    const location = useLocation();
    const { slug } = useParams();

    if (slug === "") navigate("/");

    const [loading, setLoading] = useState(props.courseList.filter((item) => item.Slug === slug).length < 1 );
    let courseData = props.courseList.filter((item) => item.Slug === slug);
    let department_code = courseData.length > 0 ? courseData[0].DEPT_CODE : "";
    const [search, setSearch] = useState("");
    const [level, setLevel] = useState([]);
    const [semester, setSemester] = useState([]);
    const [moduleList, setModuleList] = useState(props.moduleList.filter((item) => item.DepartmentCode === department_code));
    const [programmeList, setProgrammeList] = useState(props.programmeList.filter((item) => item.DepartmentCode === department_code));
    const [staffList, setStaffList] = useState(props.staffList.filter((item) => item.DepartmentCode === department_code));
    const [courses, setCourses] = useState(props.courseList);
    const [courseDetail, setCourseDetail] = useState(
        courseData.length > 0
            ? props.courseList.filter(
                (item) => item.DEPT_CODE === department_code
            )
            : []
    );

    const levelObj = (lvl, sem) => {
        let result = [];
        for (let i = 0; i < lvl.length; i++) {
            for (let j = 0; j < sem.length; j++) {
                result.push( {LEVEL: lvl[i], SEMESTER: sem[j]})
            }

        }
        return result;
    }

    const getData = async () => {
        await axios
            .get(`${serverLink}general/course/content`)
            .then((result) => {
                const departmentData = result.data.Department.filter((item) => item.Slug === slug);
                if (departmentData.length > 0){
                    let departmentCode = departmentData[0].DEPT_CODE;
                    const moduleData = result.data.Course.filter((item) => item.DepartmentCode === departmentCode);
                    const programmeData = result.data.Programme.filter((item) => item.DepartmentCode === departmentCode);
                    const staffData = result.data.Staff.filter((item) => item.DepartmentCode === departmentCode);
                    setCourses(result.data.Department);
                    setLevel(levelObj([...new Set(moduleData.map(e=>e.LEVEL))], [...new Set(moduleData.map(e=>e.SEMESTER))]));
                    setSemester([...new Set(moduleData.map(e=>e.SEMESTER))]);
                    setCourseDetail(departmentData);
                    setModuleList(moduleData);
                    setProgrammeList(programmeData);
                    setStaffList(staffData);
                    props.setOnCourseDetails(result.data.Department);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        getData();
    }, [""]);

    const onCourseChange = (e) => {
        const selectedCourse = e.target.value;
        setSearch(selectedCourse);
    };

    const handleOnFacultyClick = (e) => {
        setCourses(props.courseList.filter((item) => item.COL_ID === e));
    };

    return loading ? (
        <section className="space-ptb position-relative">
            <div className="container" style={{height: '400px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
        </section>
    ) : (
        <>
            <PageName page={courseDetail.length > 0 ? courseDetail[0].DEPARTMENT : slug} />
            <Banner
                Image={courseDetail.length > 0 ? `${serverLink}public/uploads/course_uploads/${courseDetail[0].ImagePath}` : require("../../../images/blog/03.jpg")}
                Title={courseDetail.length > 0 ? courseDetail[0].DEPARTMENT : slug}
                breadcrumb={["Admission", "Our Courses", courseDetail.length > 0 ? courseDetail[0].DEPARTMENT : slug]}
                height="50px"
            />

            <section className="space-ptb">
                <div className="" style={{marginLeft:"20px", marginRight:"20px"}}>
                    <div className="row">
                        <div className="col-lg-9 mb-5 mb-lg-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="shadow border-radius p-4">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <img className="img-fluid border-radius img-thumbnail w-100" style={{maxHeight: '380px'}} src={courseDetail.length > 0 ? `${serverLink}public/uploads/course_uploads/${courseDetail[0].ImagePath}` : require("../../../images/blog/03.jpg")} alt=""/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="">
                                                            <nav className="mt-2">
                                                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                                    <a className="nav-item nav-link active col-sm-4" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true" style={{border: '1px solid #218F76'}}>Course Details</a>
                                                                    <a className="nav-item nav-link col-sm-4" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false" style={{border: '1px solid #218F76'}}>Modules</a>
                                                                    <a className="nav-item nav-link col-sm-4" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false" style={{border: '1px solid #218F76'}}>Staff</a>
                                                                </div>
                                                                <hr style={{marginTop: '3px', marginBottom: '5px', backgroundColor: '#cccccc'}} />
                                                            </nav>
                                                            <div className="tab-content bg-white " id="nav-tabContent">
                                                                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                                    <div className="section-title mb-2">
                                                                        <h3 className="title">Course Programmes</h3>
                                                                        <hr style={{marginTop: '0px', marginBottom: '5px', backgroundColor: '#cccccc'}} />
                                                                    </div>
                                                                    {
                                                                        programmeList.length > 0 ?
                                                                            programmeList.map((item, index)=> {
                                                                                return (
                                                                                    <div key={index} className="section-title mb-2">
                                                                                        <h5 className="title text-secondary">{item.PROGRAMME}</h5>
                                                                                        <div  className="mb-3">
                                                                                            <b>Requirements for admission: </b><br/>
                                                                                        </div>

                                                                                        <p className="mb-0 text-justify" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.ENTRY_REQUIREMENT)}}/>
                                                                                        <div className="table-responsive-md ">
                                                                                            <table className="table table-responsive-md">
                                                                                                <tr>
                                                                                                    <th width={200}>Programme:</th>
                                                                                                    <td>{item.PROGRAMME}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Programme Type:</th>
                                                                                                    <td>{item.STUDY_TYPE}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Entry Level:</th>
                                                                                                    <td>{item.ENTRY_LEVEL} Level</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Application Fee:</th>
                                                                                                    <td>{currencyConverter(item.APP_FEE)}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>Acceptance Fee:</th>
                                                                                                    <td>{currencyConverter(item.ACCEPT_FEE)}</td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </div>

                                                                                        <hr style={{marginTop: '3px', marginBottom: '5px', backgroundColor: '#cccccc'}} />
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            :
                                                                            <div className="alert alert-info text-center">No Programme Found</div>

                                                                    }
                                                                    <div className="section-title mb-4">
                                                                        <p className="mb-2 pb-2 text-success">This Programmes is currently accepting application.</p>
                                                                        <a href="https://odfel.kadunapoly.edu.ng/application/apply_now" className="btn btn-primary btn-round">Apply Now</a>
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                                    <div className="section-title mb-3">
                                                                        <h3 className="title">Course Modules</h3>
                                                                    </div>
                                                                   <div className="table-responsive-md">
                                                                       {
                                                                           level.length > 0 ?
                                                                               level.sort((a,b)=> a.LEVEL - b.LEVEL).map((item, index)=>{
                                                                                   return(
                                                                                       <table key={index} className="table table-responsive-md table-striped table-hover w-100">
                                                                                           <thead>
                                                                                           <tr>
                                                                                               <th width={200}>Module Code</th>
                                                                                               <th width={800}>Module Title</th>
                                                                                               <th>Level</th>
                                                                                               <th>Semster</th>
                                                                                           </tr>
                                                                                           </thead>
                                                                                           <tbody>
                                                                                           {
                                                                                               moduleList.filter(e=>e.LEVEL.toString() === item.LEVEL.toString() && e.SEMESTER.toString() === item.SEMESTER.toString()).length > 0 ?
                                                                                                   moduleList.filter(e=>e.LEVEL.toString() === item.LEVEL.toString() && e.SEMESTER.toString() === item.SEMESTER.toString()).map((module, i)=> {
                                                                                                       return (
                                                                                                           <tr key={i}>
                                                                                                               <td>{module.COURSE_CODE}</td>
                                                                                                               <td>{module.COURSE_TITLE}</td>
                                                                                                               <td>{module.LEVEL}00</td>
                                                                                                               <td>{module.SEMESTER.toString() === "1" ? "First" : "Second"}</td>
                                                                                                           </tr>
                                                                                                       )
                                                                                                   })
                                                                                                   :
                                                                                                   <tr>
                                                                                                       <td colSpan={4}><div className="alert alert-info text-center">No Module Found</div></td>
                                                                                                   </tr>

                                                                                           }
                                                                                           </tbody>
                                                                                       </table>
                                                                                   )
                                                                               })
                                                                               :
                                                                               <div className="alert alert-info text-center">No Module Found</div>
                                                                       }

                                                                   </div>
                                                                </div>
                                                                <div className="tab-pane fade" id="nav-contact" role="tabpanel"  aria-labelledby="nav-contact-tab">
                                                                    <div className="section-title mb-3">
                                                                        <h3 className="title">Staff</h3>
                                                                    </div>
                                                                    {
                                                                        staffList.length > 0 ?
                                                                                    <table className="table table-responsive-md table-striped table-hover w-100">
                                                                                        <thead>
                                                                                        <tr>
                                                                                            <th>Staff Name</th>
                                                                                            <th>Department</th>
                                                                                            <th>Designation</th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        {
                                                                                            staffList.length > 0 ?
                                                                                                staffList.map((staff, i)=> {
                                                                                                    return (
                                                                                                        <tr key={i}>
                                                                                                            <td><Link to={`/academics/staff/${staff.Slug}`} className="">{staff.StaffName}</Link></td>
                                                                                                            <td>{staff.DepartmentName}</td>
                                                                                                            <td>{staff.Designation}</td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                })
                                                                                                :
                                                                                                <tr>
                                                                                                    <td colSpan={4}><div className="alert alert-info text-center">No module Found</div></td>
                                                                                                </tr>

                                                                                        }
                                                                                        </tbody>
                                                                                    </table>

                                                                            :
                                                                            <div className="alert alert-info text-center">No Staff Found</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <CourseSide
                            courseList={courses}
                            onCourseChange={onCourseChange}
                            handleOnFacultyClick={handleOnFacultyClick}
                        />


                    </div>
                </div>
            </section>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        courseList: state.DepartmentListDetails,
        moduleList: state.CourseListDetails,
        staffList: state.StaffListDetails,
        programmeList: state.ProgrammeDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
        setOnCourseDetails: (p) => {
            dispatch(setDepartmentListDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);
