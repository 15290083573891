import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../images/logo.jpg";
import {
  formatDateAndTime,
  projectAddress, projectEmail, projectFacebook, projectInstagram, projectLinkedIn, projectLogo,
  projectName,
  projectPhone, projectStaffURL, projectStudentURL, projectTwitter,
} from "../../../resources/url";

export default function Footer() {
  return (
    <>
      <footer className="space-pt bg-overlay-black-90 bg-holder footer mt-n5" style={{backgroundImage: `url(${projectLogo})`}}>
        <div className="container pt-5">
          <div className="row pb-5 pb-lg-6 mb-lg-3">
            <div className="col-sm-6 col-lg-4 mb-4 mb-lg-0 pr-lg-5">
              <a href="/">
                <img className="img-fluid mb-3" src={logo} alt={projectName}  />
              </a>

              <p className="text-white text-justify mt-2">A best-value, first-choice Polytechnic offering signature programmes that prepare students for the changing needs of the workplace's</p>

              <h5 className="text-white mb-2 mb-sm-4">Follow Us</h5>
              <div className="social-icon social-icon-style-02">
                <ul>
                  <li><a href={projectFacebook} target="_blank"><i className="fab fa-facebook-f"/></a></li>
                  <li><a href={projectTwitter} target="_blank"><i className="fab fa-twitter"/></a></li>
                  <li><a href={projectLinkedIn} target="_blank"><i className="fab fa-linkedin-in"/></a></li>
                  <li><a href={projectInstagram} target="_blank"><i className="fab fa-instagram"/></a></li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-lg-2 mb-4 mb-lg-0">
              <h5 className="text-white mb-2 mb-sm-4">Admission</h5>
              <div className="footer-link">
                <ul className="list-unstyled mb-0">
                  <li><Link className="text-white" to={"/admission/courses"}>Our Courses</Link></li>
                  <li><a className="text-white" href={"https://odfel.kadunapoly.edu.ng/application/apply_now"}  target="_blank">Apply Now</a></li>
                  <li><a className="text-white" href={"https://odfel.kadunapoly.edu.ng/application/applicant_login"} target="_blank">Applicant Login</a></li>
                  <li><Link className="text-white" to={"/admission/check-admission-status"} >Check Admission Status</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-lg-2 mb-4 mb-sm-0">
              <h5 className="text-white mb-2 mb-sm-4">Resources</h5>
              <div className="footer-link">
                <ul className="list-unstyled mb-0">
                  <li><Link className="text-white" to={"/resources/news"}>News</Link></li>
                  <li><Link className="text-white" to={"/resources/gallery"}>Gallery</Link></li>
                  <li><Link className="text-white" to={"/portal/student-login"} >Student Portal</Link></li>
                  <li><a className="text-white" href={projectStaffURL} target="_blank">Staff Portal</a></li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <h5 className="text-white mb-2 mb-sm-4">Contact Us</h5>
              <p className="text-white">{projectName}</p>
              <div className="footer-contact-info">
                <div className="contact-address mt-4">
                  <div className="contact-item">
                    <i className="fas fa-map-marker-alt"/>
                    <p className="ml-3 mb-0 text-white">{projectAddress}</p>
                  </div>
                  <div className="contact-item">
                    <i className="fas fa-microphone-alt"/>
                    <p className="mb-0 font-weight-bold ml-3"><a className="text-white" href={`tel:${projectPhone}`}>{projectPhone}</a></p>
                  </div>
                  <div className="contact-item mb-0">
                    <i className="fas fa-headset"/>
                    <a className="text-white ml-3 text-white" href={`mailto:${projectEmail}`}>{projectEmail}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom bg-dark py-4">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 text-center">
                <p className="mb-0 text-white">©Copyright {formatDateAndTime(new Date(), 'year_only')} {projectName} All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div id="back-to-top" className="back-to-top" onClick={() => window.scrollTo(0, 0)}>up</div>
    </>
  );
}
