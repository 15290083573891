import axios from "axios";
const CryptoJS = require("crypto-js");

const status = "Live";
export const serverLink =
    status === "Dev"
        // ? "http://localhost:4480/"
        ? "http://192.168.1.75:4480/"
        : "https://api.teamcoded.com.ng:4480/";

export const paystackAPIKey = "pk_live_5e3596837a61d6eedd7a5e630d642e14cca3cd63";
export const simpleFileUploadAPIKey = "305e0ddf925a3fc5dd34d314f8230349";
export const projectName = "ODFeL-Kaduna Polytechnic";
export const projectCode = "ODFeL_KADUNA_POLYTECHNIC";
export const serverStatus = status;
export const projectURL = "https://odfel.kadunapolytechnic.edu.ng/";
export const projectPhone = " +234-8036424139, +234-8098909053, +234-8180599505";
export const projectEmail = "odfel@kadunapoly.edu.ng";
export const projectAddress = "Poly Road T/Wada, Kaduna State Nigeria.";
export const projectStudentURL = "https://odfel.kadunapoly.edu.ng/student-login.php";
export const projectStaffURL = "https://odfel.kadunapoly.edu.ng/staff";
export const projectLogo = "https://odfel.kadunapoly.edu.ng/logo.png";
export const projectDirector = "Dr. Naseer Ajoge"
export const shortCode = "ODFeL"


//TESTING REMITA KEY
export const MERCHANTID = "542778173"
export const SERVICETYPEID = "583859577"
export const APIKEY = "286513"
export const GATEWAYURL = "https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api/paymentinit"
export const GATEWAYRRRPAYMENTURL = "https://login.remita.net/remita/ecomm/finalize.reg"
export const CHECKSTATUSURL = "https://login.remita.net/remita/ecomm"
export const URL_REDIRECT = window.location.host+"/admission/check-admission-status";

// SOCIAL MEDIA
export const projectFacebook = "https://www.facebook.com/";
export const projectTwitter = "https://www.twitter.com/";
export const projectInstagram = "https://instagram.com/?lang=en";
export const projectLinkedIn = "https://linkedin.com/?lang=en";


export function encryptData(string, val = false) {
  try {
    let secret_key = val === false ? "TEAMCODED" : projectCode;
    let secret_iv = val === false ? "TEAMCODED" : projectCode;
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

    let result = decrypted.toString();
    return btoa(result);
  }catch (e) {
    console.log(e)
  }
}

export function decryptData(string, val = false) {
 try {
   let secret_key = val === false ? "TEAMCODED" : projectCode;
   let secret_iv = val === false ? "TEAMCODED" : projectCode;
   // hash
   let kee = CryptoJS.SHA256(secret_key);
   let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

   kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
   ivv = CryptoJS.enc.Utf8.parse(ivv);

   var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

   return decrypted.toString(CryptoJS.enc.Utf8);
 }catch (e) {
   console.log(e)
 }
}

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formatDateAndTime = (date, option) => {
  if (date !== null) {
    const user_date = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day =
      user_date.getDate() < 10
        ? "0" + user_date.getDate()
        : user_date.getDate();
    const hour =
      user_date.getHours() < 10
        ? "0" + user_date.getHours()
        : user_date.getHours();
    const min =
      user_date.getMinutes() < 10
        ? "0" + user_date.getMinutes()
        : user_date.getMinutes();
    const sec =
      user_date.getSeconds() < 10
        ? "0" + user_date.getSeconds()
        : user_date.getSeconds();

    let date_string = "";
    if (option === "date_and_time")
      date_string = `${day}-${monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
    else if (option === "date")
      date_string = `${day}-${monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()}`;
    else if (option === "day") date_string = day;
    else if (option === "full_month")
      date_string = monthNames[user_date.getMonth()];
    else if (option === "short_month")
      date_string = monthNamesShort[user_date.getMonth()];
    else if (option === "year_only") date_string = user_date.getFullYear();

    return date_string;
  } else {
    return "--";
  }
};

export const sendEmail = (email, subject, title, name, body, signature) => {
  const sendEmail = {
    logo: projectLogo,
    from: projectEmail,
    to: email,
    subject: subject,
    title: title,
    name: name,
    body: body,
    signature: signature,
  };
  axios.post(`${serverLink}send_email/send`, sendEmail).then((r) => {
    //console.log("email sent");
  });

  return "sent";
};

export const currencyConverter = (amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NGN',
  });
  return formatter.format(amount)
}

export const EmailTemplates = (type, details) => {
  if (type === '1') {
    const message = {
      subject: 'password recovery',
      title: '',
      body: `<div style="line-height: 1.6">Your job application portal password is ${details.Password} <br/><br/>For further enquiries, please contact <b/>${projectEmail}<br/>${projectPhone}</div>`
    }
    return message;
  }
  else if (type === '2') {
    const message = {
      subject: 'Admission',
      title: 'Password Recovery',
      body: `<div style="line-height: 1.6">
      You have requested to reset your password, kinndly follow this <a href='${projectURL}admission/application/reset-password/${details}'>link</a> to reset your password.
       <br/><br/>For further enquiries, please contact <b/>${projectEmail}<br/>${projectPhone}
      </div>`
    }
    return message;
  }
};

export const visionStatement =
  `The vision of Al-Ansar University is "to be a world-class academic institution where faith, innovation and service are nurtured for the advancement of human development."`;
export const missionStatement =
  `The mission of Al-Ansar University is “to be a beacon of knowledge and transformative learning seeking to advance professionalism, build a diverse and socially inclusive society, and be a powerhouse for human capital development."`;
export const historyStatement = "";
